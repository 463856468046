@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lexend:wght@200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* third-party libraries CSS */
.tns-controls {
  @apply absolute left-1/2 bottom-14 z-30 flex -translate-x-1/2 space-x-5 lg:left-[70px] lg:translate-x-0;
}

.tns-controls button {
  @apply flex h-10 w-10 items-center justify-center rounded-full bg-primary bg-opacity-10 text-primary hover:bg-opacity-100 hover:text-white dark:bg-white dark:bg-opacity-10 dark:text-white dark:hover:bg-primary dark:hover:bg-opacity-100 dark:hover:text-white;
}

@layer base {
  *,
  ::before,
  ::after {
    @apply border-stroke;
  }

  body {
    @apply font-body;
  }
}

@layer components {
  html {
    @apply scroll-smooth;
  }

  .header {
    @apply py-5;
  }

  .sticky {
    @apply !fixed !z-[9999] !bg-white !bg-opacity-80 !py-2 !transition dark:!bg-dark;
    backdrop-filter: blur(5px);
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
  }

  button.bg-blue-700 {
    @apply bg-orange-500 !important;
  }

  ul > a:first-child {
    @apply md:ml-0 !important;
  }

  ul > a:not(:first-child) {
    @apply md:ml-3 !important;
    @apply lg:ml-4 !important;
  }

  .navbar > li > a {
    @apply md:py-1;
    @apply lg:py-7;
  }

  .sticky .navbar > li > a {
    @apply lg:py-5;
  }

  .menu-scroll.active {
    @apply text-primary;
  }

  .menu-wrapper {
    @apply hidden;
  }

  .menu-wrapper.show {
    @apply flex;
  }

  .title {
    background: linear-gradient(
      180deg,
      rgba(74, 108, 247, 0.4) 0%,
      rgba(74, 108, 247, 0) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    opacity: 0.2;
    @apply absolute left-1/2 top-0 -translate-x-1/2 text-[40px] font-extrabold !leading-none sm:text-[60px] lg:text-[95px];
  }

  .dark .title {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  #supportCheckbox:checked ~ div span {
    @apply opacity-100;
  }

  .submenu-item a.active span svg {
    @apply rotate-180;
  }

  .grid-sizer {
    @apply w-full md:w-1/2 lg:w-1/3;
  }

  .portfolio-btn-wrapper button.active {
    @apply underline;
  }
}
